<template>
  <main class="navbar-offset-tablet">
    <PageNavigator
      :page-title="$t('salon-employees.breadcrumbs-list-label')"
      :has-back="true"
      :button-back-link="'/salon'"
    >
      <template v-slot:action>
        <div class="d-flex">
          <div>
            <p id="service-info-1" class="pr-1 mt-1 mb-0">
              <font-awesome-icon
                v-b-tooltip
                class="fa-w-16 ml-0-5 tooltipInfoIcon"
                style="font-size:20px;"
                data-toggle="tooltip"
                :icon="['fas', 'info-circle']"
              ></font-awesome-icon>
            </p>
            <b-tooltip
              target="service-info-1"
              :popover-style="{ color: '#ffffff !important', background: '#fff !important' }"
              :custom-class="'show'"
              class="mr-5 show"
              triggers="focus hover"
              placement="right"
            >
              <div class="text-left">
                <span class="mb-2" v-html="$t('salon-employees.tooltip.roles_1')"></span>
                <br />
                <span class="mb-2" v-html="$t('salon-employees.tooltip.roles_2')"></span>
                <br />

                <span class="mb-2" v-html="$t('salon-employees.tooltip.roles_3')"></span>
                <br />
              </div>
            </b-tooltip>
          </div>
          <b-link :to="'/salon/staff/manager-create'" class="btn btn-primary px-4 py-1 mr-1-5">
            <span class="font-weight-medium poppins-font">
              {{ $t('salon-employees.add_manager_button') }}
            </span>
          </b-link>
          <b-link :to="'/salon/staff/create'" class="btn btn-primary px-4 py-1">
            <span class="font-weight-medium poppins-font">
              {{ $t('salon-employees.add_staff_button') }}
            </span>
          </b-link>
        </div>
      </template>
    </PageNavigator>

    <div class="px-1-5 pt-7-5 full-page-height salon-flow">
      <div class="d-flex flex-wrap h-100 justify-content-center">
        <StaffsTable
          v-if="!isStaffLoading"
          :items="staff.data"
          @change-sort="changeSort"
          @delete-item="deleteWorker"
        ></StaffsTable>
        <CustomPagination
          :data="staff"
          :show-disabled="true"
          @pagination-change-page="changePaginationPage"
        ></CustomPagination>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import StaffsTable from '@/components/salon/StaffsTable'
import PageNavigator from '@/components/PageNavigator'
import CustomPagination from '@/components/CustomPagination'

export default {
  name: 'SalonsList',
  components: { StaffsTable, PageNavigator, CustomPagination },
  data() {
    return {
      isStaffLoading: false,
      payloadData: {
        salon_id: null,
        page: 1,
        sort: '-user.email'
      },
      deleteLastInfoMessage: {
        type: 'info',
        messageI18Key: 'common.alerts.actions.delete_last_info'
      },
      deleteErrorMessage: {
        type: 'error',
        messageI18Key: 'common.alerts.actions.global_error'
      }
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo',
      staff: 'salonsStaff/getSalonsStaffInfo'
    })
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions({
      deleteStaffAction: 'salonsStaff/deleteStaff',
      deleteManagerAction: 'salonsStaff/deleteManager',
      getStaffBySalonId: 'salonsStaff/getStaffBySalonId',
      setError: 'userMessages/setError',
      setSuccess: 'userMessages/setSuccess'
    }),
    setLoadingFlag(val) {
      this.isStaffLoading = val
    },
    changeSort(val) {
      this.payloadData.sort = val

      this.getStaffBySalonId(this.payloadData)
    },
    changePaginationPage(page = 1) {
      this.payloadData.page = page

      this.fetchData()
    },
    setPreviousPageForRequest() {
      if (
        this.staff.data.length === 0 &&
        this.staff.current_page === this.staff.last_page &&
        this.staff.current_page !== 1
      ) {
        this.payloadData.page = this.payloadData.page - 1
      }
    },
    confirmMessage() {
      const h = this.$createElement
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h6 text-center mb-0 text-black pt-2-5 pb-1-5'] }, [this.$t('common.confirm.delete_title')])
      ])

      return this.$bvModal
        .msgBoxConfirm([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4 has-cusstom-btns',
          bodyClass: ' pb-4 px-4',
          cancelVariant: 'light',
          cancelTitle: `${this.$t('alert_message.cancel_title')}`
        })
        .then(value => {
          return value
        })
        .catch(error => {
          return error
        })
    },
    deleteProcess(workerData) {
      this.setLoadingFlag(true)

      if (workerData.role === 'Staff') {
        this.deleteStaffAction(workerData)
          .then(() => {
            if (this.staff.data.length === 0 || this.staff.total === 10) {
              this.setPreviousPageForRequest()
              this.fetchData()
            }

            this.setLoadingFlag(false)

            this.setSuccess({
              messageI18Key: `common.alerts.actions.deleted`,
              successCode: 200,
              successExists: true
            })
          })
          .catch(error => {
            this.setLoadingFlag(false)

            if (error.message === "You can't delete last staff") {
              this.setError(this.deleteLastInfoMessage)
            } else {
              this.setError(this.deleteErrorMessage)
            }
          })
      } else if (workerData.role === 'Manager' || workerData.role === 'Salon Manager') {
        this.deleteManagerAction(workerData)
          .then(() => {
            if (this.countOnPage === 0 || this.staff.total === 10) {
              this.setPreviousPageForRequest()
              this.fetchData()
            }

            this.setLoadingFlag(false)

            this.setSuccess({
              messageI18Key: `common.alerts.actions.deleted`,
              successCode: 200,
              successExists: true
            })
          })
          .catch(error => {
            this.setLoadingFlag(false)

            if (error.message === "You can't delete last staff") {
              this.setError(this.deleteLastInfoMessage)
            } else {
              this.setError(this.deleteErrorMessage)
            }
          })
      }
    },
    deleteWorker(workerData) {
      this.confirmMessage()
        .then(value => {
          if (value) {
            this.deleteProcess(workerData)
          }
        })
        .catch(error => {})
    },
    fetchData() {
      if (this.accountInfo.salon_id) {
        this.payloadData.salon_id = this.accountInfo.salon_id

        this.setLoadingFlag(true)

        this.getStaffBySalonId(this.payloadData)
          .then(() => this.setLoadingFlag(false))
          .catch(() => {
            this.setLoadingFlag(false)
            this.setError({
              errorType: 'staffs_loading',
              messageI18Key: 'common.alerts.actions.global_error'
            })
          })
      }
    }
  }
}
</script>
