<template>
  <table class="table table-bordered">
    <thead class="table-bordered">
      <tr>
        <th
          v-for="(th, key) in thList"
          :key="`th-${key}`"
          :style="`width: ${th.width};`"
          :class="{ 'cursor-pointer': th.sortBy }"
          class="text-small roboto-font font-weight-medium py-1-5 pl-1-5 pr-1"
          @click="th.sortBy ? chageSortEmit(th.sortBy) : ''"
        >
          <div class="d-flex justify-content-between">
            {{ $t(th.i18KeyLabel) }}
            <div v-if="th.sortBy" class="d-flex flex-column text-gray position-relative">
              <font-awesome-icon
                :icon="['fas', 'sort-up']"
                class="fa-w-10"
                :class="{ 'text-black': sortedUp(th.sortBy) }"
              ></font-awesome-icon>
              <font-awesome-icon
                :icon="['fas', 'sort-down']"
                class="fa-w-10"
                :class="{ 'text-black': sortedDown(th.sortBy) }"
              ></font-awesome-icon>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, key) in items" :key="`service-${key}`">
        <td class="py-1 px-1-5">
          <img
            style="border-radius: 2px;"
            width="30px"
            height="30px"
            :src="item.avatar ? item.avatar.path : ''"
            alt=""
          />
        </td>

        <td class="py-0-7 px-1-5">{{ item.user.first_name }}</td>
        <td class="py-0-7 px-1-5">{{ item.user.last_name }}</td>
        <td class="py-0-7 px-1-5">{{ item.user.email }}</td>
        <td class="py-0-7 px-1-5">{{ $t(`role.${item.role}`) }}</td>

        <!-- @Aug 07, 2019 6:19 am -->
        <td class="py-0-7 px-1-5">{{ item.created_at | dateFormat(siteLang) }}</td>
        <td class="py-0-7 px-1-5">
          <div class="d-flex action-btns">
            <b-link
              :to="
                item.role === 'Staff' || item.role === 'Salon Manager'
                  ? `/salon/staff/update/${item.id}`
                  : `/salon/staff/manager-update/${item.id}`
              "
              class="btn btn-checkbox mr-1-5"
            >
              <font-awesome-icon :icon="['fas', 'pencil']" class="fa-w-16"></font-awesome-icon>
            </b-link>

            <button
              class="btn btn-checkbox"
              @click="deleteItemEmit({ id: item.role === 'Salon Manager' || item.role === 'Manager' ? item.user.id : item.id, role: item.role })"
            >
              <font-awesome-icon :icon="['fas', 'trash-alt']" class="fa-w-14"></font-awesome-icon>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'StaffsTable',
  filters: {
    dateFormat(val, locale) {
      return moment(val)
        .locale(locale)
        .format('LLL')
    }
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sortedBy: '',
      thList: [
        {
          i18KeyLabel: 'salon-employees.table-label.photo',
          sortBy: '',
          width: '62px'
        },
        {
          i18KeyLabel: 'salon-employees.table-label.first_name',
          sortBy: 'user.first_name',
          width: '196px'
        },
        {
          i18KeyLabel: 'salon-employees.table-label.last_name',
          sortBy: 'user.last_name',
          width: 'auto'
        },
        {
          i18KeyLabel: 'salon-employees.table-label.email',
          sortBy: 'user.email',
          width: '196px'
        },
        {
          i18KeyLabel: 'salon-employees.table-label.role',
          label: 'Role',
          sortBy: 'role',
          width: 'auto'
        },
        {
          label: 'Created At',
          i18KeyLabel: 'salon-employees.table-label.created_at',
          sortBy: 'created_at',
          width: '213px'
        },
        {
          i18KeyLabel: 'salon-employees.table-label.action',
          sortBy: '',
          width: '105px'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      siteLang: 'user/getSiteLang'
    })
  },
  methods: {
    deleteItemEmit(itemData) {
      this.$emit('delete-item', itemData)
    },
    sortedUp(val) {
      return this.sortedBy === val
    },
    sortedDown(val) {
      return this.sortedBy === `-${val}`
    },
    chageSortEmit(val) {
      if (this.sortedBy === val) val = `-${val}`

      this.sortedBy = val
      this.$emit('change-sort', val)
    }
  }
}
</script>

<style lang="scss">
.salon-flow .table .action-btns button {
  padding: 0.125rem 0;
  width: 1.875rem;
  text-align: center;
}
</style>
